import { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import ReactDOM from 'react-dom';
import { Dropdown } from 'react-bootstrap';
import autoBind from 'react-autobind';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

import * as authentication from '../../../actions/authentication.actions';

import FilterButtonInfo from '../StoryFiltersButton/FilterButtonInfo';
import SwitchButton from '../../MainTrending/TrendingFilters/TrendingPersonalisationFilter/SwitchButton';
import FiltersMobileModal from '../FiltersMobileModal';

import withComponentName from '../../../decorators/withComponentName';

import { initialState } from '../../../data/filters/storyFilters';

import StorageSvc from '../../../services/StorageSvc';
import LanguagesSvc from '../../../services/dbServices/LanguagesSvc';

import { getUserToken } from '../../../helpers/getUserToken';

import Styles from '../story-filters-styles.module.scss';
import UpgradeTooltipSmall from '../../GeneralComponents/UpgradeTooltip/UpgradeTooltipSmall';
import { GOLD, SILVER } from '../../../data/permissions';

const cx = classNames.bind(Styles);

export class LanguageFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchMode: false,
      searchValue: '',
      openFilterModal: false,
      isLoading: true,
      dropdown: {
        selectAll: false,
        open: false,
        selected: [],
        options: [],
        optionsSaved: [],
      },
    };
    this.timer = null;
    this.switchButton = null;
    autoBind(this);
  }

  componentDidMount() {
    const {
      savedFilterValue,
      defaultSelectedLanguages,
      selectedAllLanguages,
      token,
    } = this.props;

    const presistedState = StorageSvc.getItem('LanguageFilter');

    if (savedFilterValue.all_languages || selectedAllLanguages) {
      this.fetchLanguages('all');
    } else if (savedFilterValue.lang) {
      this.fetchLanguages(savedFilterValue.lang.split(','));
    } else if (presistedState !== null && savedFilterValue.lang !== defaultSelectedLanguages) {
      const rehydrate = JSON.parse(presistedState);
      this.setState({
        isLoading: false,
        ...rehydrate,
      });
    } else if (presistedState === null && !token) {
      this.fetchLanguages(defaultSelectedLanguages.split(','));
    } else {
      this.fetchLanguages();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      savedFilterValue, selectedAllLanguages,
    } = this.props;
    const { dropdown } = this.state;

    // only fire this when languages changed from another component
    if (
      savedFilterValue.lang !== prevProps.savedFilterValue.lang
    ) {
      this.fetchLanguages(savedFilterValue.lang.split(','));
    } else if (
      selectedAllLanguages !== prevProps.selectedAllLanguages
      && !dropdown.open
      && selectedAllLanguages
    ) {
      // fire this method when resetting languages in recommended stories component
      this.fetchLanguages('all');
    }
  }

  componentWillUnmount() {
    const { token } = this.props;

    clearTimeout(this.timer);

    // save to localstorage for guest user
    if (!token) this.saveState();
  }

  handleCloseModal() {
    this.setState({ openFilterModal: false });
  }

  onType(evt) {
    const { dropdown } = this.state;

    const query = evt ? ReactDOM.findDOMNode(evt.target).value : '';

    this.setState({
      searchMode: !!query,
      searchValue: query,
      dropdown: {
        ...dropdown,
        options: query ? dropdown.options : this.sortOptions(dropdown.options),
      },
    });

    // clear previous requests if user typed many chars
    clearTimeout(this.timer);

    // only send request when there is a query
    this.timer = setTimeout(() => {
      this.search(query.toLowerCase());
    }, 500);
  }

  onChangeSwitchIcon(status) {
    const {
      translateAction,
      translationsDisabled,
      piwikEnabled,
      token,
      actions,
      freezeStories,
    } = this.props;

    freezeStories(false);
    if (!getUserToken()) {
      actions.setAuthModalOpened(true);
    } else if (translationsDisabled) {
      translateAction(status, token);
    }

    if (piwikEnabled && status) _paq.push(['trackEvent', 'Filters', 'Translate content ON']);
    if (piwikEnabled && !status) _paq.push(['trackEvent', 'Filters', 'Translate content OFF']);
  }

  setValues(values) {
    const { dropdown, searchValue } = this.state;

    const selected = values.filter((item) => item.select);

    const sortedValues = this.sortOptions(values);

    const filterOptions = sortedValues.map((item) => ({
      ...item,
      filtered: item.name.toLowerCase().startsWith(searchValue.toLowerCase()),
    }));

    this.setState({
      isLoading: false,
      dropdown: {
        ...dropdown,
        selectAll: values.length === selected.length,
        options: filterOptions,
        selected,
      },
    }, () => {
      const { token } = this.props;
      if (!token) this.saveState();
    });
  }

  setValueSelected(selectedItem) {
    const { dropdown } = this.state;
    const {
      freezeStories, action, piwikEnabled, token,
    } = this.props;

    freezeStories(false);

    const newSelectedItem = this.selectItem(selectedItem);

    const newOptions = dropdown.options;
    newOptions[newOptions.indexOf(selectedItem)] = newSelectedItem;

    const newSelected = [];
    newOptions.forEach((item) => { if (item.select) newSelected.push(item); });

    this.setState({
      dropdown: {
        ...dropdown,
        selectAll: newOptions.length === newSelected.length,
        options: newOptions,
        selected: newSelected,
        optionsSaved: [...dropdown.optionsSaved, newSelectedItem.id],
      },
    }, () => {
      const { dropdown } = this.state;
      const actionPayload = newSelected.map((item) => (
        item.short
      ));

      if (piwikEnabled) {
        _paq.push(
          newSelectedItem.select ? (
            ['trackEvent', 'Filters', 'Add language', newSelectedItem.name]
          ) : (
            ['trackEvent', 'Filters', 'Delete language', newSelectedItem.name]
          ),
        );
      }

      action(actionPayload.toString(), dropdown.selectAll, token);

      this.saveState();

      // remove saved text after 3 seconds
      setTimeout(() => {
        this.setState((state) => {
          const { dropdown } = state;
          let selectedAllOptions;
          if (dropdown.selectAll) {
            selectedAllOptions = dropdown.options.map((item) => this.selectItem(item, true));
          }
          const optionsForSorting = selectedAllOptions || newOptions;
          return {
            dropdown: {
              ...state.dropdown,
              options: this.sortOptions(optionsForSorting),
              optionsSaved: state.dropdown.optionsSaved.filter((id) => (
                id !== newSelectedItem.id
              )),
            },
          };
        });
      }, 3000);
    });
  }

  sortOptions(options) {
    // WEB-6856 remove selected languages
    const sortedOptions = options.filter((item) => !item.select);

    const selectedOptions = options.filter((item) => item.select);
    const selectedPriorityOptions = selectedOptions.filter((item) => typeof item.order === 'number');
    const selectedNotPriorityoptions = selectedOptions.filter((item) => typeof item.order !== 'number');

    // WEB-6856 sort priority selected languages
    const sortedSelectedPriorityOptions = selectedPriorityOptions.sort((a, b) => a.order - b.order);

    // WEB-6856 sort not priority selected languages
    const sortedSelectedOptions = selectedNotPriorityoptions.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    // WEB-6856 add selected languages at beginning of the list
    sortedOptions.unshift(...sortedSelectedPriorityOptions, ...sortedSelectedOptions);

    return sortedOptions;
  }

  fetchLanguages(selectedLanguages = [], sorting = 'prioritized') {
    LanguagesSvc.getLanguages({ sorting })
      .then((languages) => {
        this.setValues([
          ...languages.map(({ name, iso_code: code }, id) => ({
            id,
            name,
            short: code,
            order: id <= 8 ? id : null,
            select: selectedLanguages === 'all' || selectedLanguages.includes(code), // set default selected languages
          })),
        ]);
      });
  }

  toggleDropdown(status, event, source = {}) {
    const { searchMode, dropdown } = this.state;
    const { freezeStories, width } = this.props;

    // workaround for IE11 to prevent double toggle
    // when user clicks on dropdown button to close dropdown
    if (
      (status === false
        && source.source === 'select')
      || (
        source.source === undefined
        && status
        && !!window.MSInputMethodContext // ie11 detection
        && !!document.documentMode
      )
    ) {
      return;
    }

    if (width <= 1024) return this.openFilterModal();

    this.setState({
      dropdown: {
        ...dropdown,
        options: status && !searchMode ? this.sortOptions(dropdown.options) : dropdown.options,
        open: status,
      },
    }, () => {
      freezeStories(status);
    });
  }

  selectItem(item, forceStatus) {
    const status = typeof forceStatus === 'boolean' ? forceStatus : !item.select;

    return {
      ...item,
      select: status,
    };
  }

  selectAll() {
    const {
      toggleAllLanguagesSelected, freezeStories, piwikEnabled, token,
    } = this.props;
    const { dropdown, searchMode } = this.state;

    freezeStories(false);

    const status = !dropdown.selectAll;
    const newOptions = dropdown.options.map((item) => this.selectItem(
      item,
      searchMode ? item.filtered && status : status,
    ));

    this.setState({
      dropdown: {
        ...dropdown,
        selectAll: status,
        options: newOptions,
        selected: newOptions.filter((item) => (
          searchMode ? item.filtered && item.select : item.select
        )),
      },
    }, () => {
      const { dropdown } = this.state;
      const actionPayload = dropdown.selected.map((item) => item.short);

      toggleAllLanguagesSelected(dropdown.selectAll, actionPayload.toString(), token);

      if (!token) this.saveState();

      if (dropdown.selectAll && piwikEnabled) _paq.push(['trackEvent', 'Filters', 'Select all languages']);
    });
  }

  search(query) {
    const { dropdown } = this.state;

    const filterOptions = dropdown.options.map((item) => ({
      ...item,
      filtered: item.name.toLowerCase().startsWith(query)
        || item.short.toLowerCase().startsWith(query),
    }));
    const selectedItemsFromFiltered = filterOptions.filter((item) => (
      item.filtered && item.select
    ));
    const matchedItemsOfFilter = filterOptions.filter((item) => (
      item.filtered
    ));

    this.setState({
      dropdown: {
        ...dropdown,
        selectAll: selectedItemsFromFiltered.length === matchedItemsOfFilter.length,
        options: filterOptions,
      },
    });
  }

  forceOpenDropdown(status) {
    this.forceOpen = status;
  }

  saveState() {
    const { dropdown } = this.state;

    if (!dropdown.options.length) return; // terminate saving if list not fetched

    StorageSvc.setItem(
      'LanguageFilter',
      JSON.stringify({
        searchMode: false,
        dropdown: {
          ...dropdown,
          open: false,
          options: dropdown.options,
        },
      }),
    );
  }

  reset() {
    this.setState({
      isLoading: true,
      searchMode: false,
      dropdown: {
        selectAll: false,
        open: false,
        selected: [],
        options: [],
        optionsSaved: [],
      },
    }, () => {
      this.fetchLanguages(initialState.languages ?? 'en');
    });
  }

  languagesButtonText() {
    const { dropdown, isLoading } = this.state;

    if (isLoading || !dropdown.selected.length) return 'Select languages';

    if (dropdown.options.length === dropdown.selected.length && dropdown.options.length) return 'All languages';

    return dropdown.selected.reduce((all, item) => `${all}${all ? ', ' : ''}${item.short}`, '').toUpperCase();
  }

  openFilterModal() {
    const { openFilterModal } = this.state;

    this.setState({ openFilterModal: !openFilterModal });
  }

  render() {
    const {
      dropdownTitle, rbi, hideTranslationBar,
      translationValue, width, hideLabel,
      hasBigScreenDesign, className = '', homePage, savedFilterValue, onboarding, hiddenFilter,
      goldUser, silverUser,
    } = this.props;
    const {
      dropdown, searchValue, searchMode, openFilterModal, isLoading,
    } = this.state;

    return (
      <Dropdown
        bsPrefix={cx('story-filters_btn', className,
          { rbi, big_screen: hasBigScreenDesign },
          { onboarding })}
        show={dropdown.open}
        onToggle={(status, event, source) => this.toggleDropdown(status, event, source)}
      >
        <Dropdown.Toggle
          id={cx(hiddenFilter ? 'source-dropdown-button-sticky' : 'source-dropdown-button')}
          variant="default"
        >
          <FilterButtonInfo
            dropdownTitle={dropdownTitle}
            openFilter={dropdown.open}
            rbi={rbi}
            width={width}
            selectedValue={this.languagesButtonText()}
            buttonErr={dropdown.selected.length === 0 && !isLoading && !savedFilterValue.lang}
            hideLabel={hideLabel}
          />
        </Dropdown.Toggle>
        {width >= 1025 ? (
          <Dropdown.Menu
            id={cx('language-dropdown')}
            rootCloseEvent="click"
            flip={false}
          >
            <FilterInner
              dropdown={dropdown}
              dropdownTitle={dropdownTitle}
              setValueSelected={this.setValueSelected}
              onChangeSwitchIcon={this.onChangeSwitchIcon}
              searchMode={searchMode}
              translationValue={translationValue}
              selectAll={this.selectAll}
              searchValue={searchValue}
              onType={this.onType}
              switchButton={this.switchButton}
              optionsContainer={this.optionsContainer}
              hideTranslationBar={hideTranslationBar}
              hasBigScreenDesign={hasBigScreenDesign}
              onboarding={onboarding}
              goldUser={goldUser}
              silverUser={silverUser}
            />
          </Dropdown.Menu>
        ) : (
          <FiltersMobileModal
            openFilter={openFilterModal}
            handleCloseModal={this.handleCloseModal}
            classname={cx('language-filter-modal', { home: homePage }, { onboarding })}
            showCloseIcon
          >
            <FilterInner
              dropdown={dropdown}
              dropdownTitle={dropdownTitle}
              setValueSelected={this.setValueSelected}
              onChangeSwitchIcon={this.onChangeSwitchIcon}
              selectAll={this.selectAll}
              translationValue={translationValue}
              searchMode={searchMode}
              onType={this.onType}
              searchValue={searchValue}
              switchButton={this.switchButton}
              optionsContainer={this.optionsContainer}
              hideTranslationBar={hideTranslationBar}
              goldUser={goldUser}
              silverUser={silverUser}
            />
          </FiltersMobileModal>
        )}
      </Dropdown>
    );
  }
}

const FilterInner = ({
  dropdown, dropdownTitle, searchValue, searchMode,
  onType, setValueSelected, selectAll, translationValue,
  onChangeSwitchIcon, hideTranslationBar, onboarding, goldUser, silverUser,
}) => (
  <div className={cx('dropdown-items-wrapper', 'language-items-wrapper', { onboarding })}>
    <span className={cx('dropdown_title')}>
      {dropdownTitle}
      :&nbsp;
    </span>
    <div className={cx('input-container')}>
      <input
        type="text"
        className={cx('dropdown_search-input')}
        placeholder="Type to search..."
        value={searchValue}
        onChange={(evt) => onType(evt)}
      />
      {searchValue && <FontAwesomeIcon className={`${cx('clear-input')}`} icon={faXmark} onClick={() => onType('')} />}
    </div>
    <div
      className={cx('menu-item__container')}
    >
      {!searchMode && (
        <Dropdown.Item
          active={dropdown.selectAll}
          onSelect={() => selectAll()}
        >
          <span className={cx('dropdown__checkbox_box')}>
            {dropdown.selectAll && <FontAwesomeIcon icon={faCheck} />}
          </span>
          <span className={cx('dropdown__checkbox_title', 'main-title')}>Select / Unselect All</span>
        </Dropdown.Item>
      )}
      {dropdown.options.map((item, index) => (
        <Dropdown.Item
          key={item.id}
          eventKey={index}
          active={item.select}
          className={cx({ hide: searchMode && !item.filtered })}
          onSelect={() => setValueSelected(item)}
        >
          <span className={cx('dropdown__checkbox_box')}>
            {item.select && <FontAwesomeIcon icon={faCheck} />}
          </span>
          <span className={cx('dropdown__checkbox_title')}>
            {item.name}
            {' '}
            (
            {item.short}
            )
          </span>
        </Dropdown.Item>
      ))}
    </div>
    {!hideTranslationBar && (
      <div className={cx('translation-option__container')}>
        <UpgradeTooltipSmall
          planBadgeText="Gold"
          isGold={goldUser}
          isSilver={silverUser}
          premiumContentFilter
          cssClass="language-gold-tooltip"
          hasBigScreenDesign
        />
        <div className={cx('translation-option__container--header', { disabled: !goldUser })}>
          <span className={cx('title')}>
            Translate Stories to English
          </span>
          <SwitchButton
            id={cx('translate__switch')}
            className="translate__switch"
            status={translationValue}
            onChange={onChangeSwitchIcon}
            hasBigScreenDesign
          />
        </div>
      </div>
    )}
  </div>
);

LanguageFilter.defaultProps = {
  hideTranslationBar: false,
  hideLabel: false,
};

function mapToStateProps({ subscriptions, userPreferencesReducer }) {
  const { permissions } = subscriptions;
  const { translations } = permissions;
  const { access_levels: accessLevels } = permissions;
  const { user } = userPreferencesReducer;

  return {
    translationsDisabled: translations,
    user,
    goldUser: accessLevels.includes(GOLD),
    silverUser: accessLevels.includes(SILVER),
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...authentication,
  }, dispatch),
});

export default withComponentName(
  connect(mapToStateProps, mapDispatchToProps, null, { forwardRef: true })(LanguageFilter),
);
