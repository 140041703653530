import { APIWithoutToken } from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingVeryHigh } from '../../data/webPageData';

const caches = {
  languagesList: {},
};

export class LanguagesSvc {
  static async getLanguages(params = {}) {
    if (caches.languagesList.response && caches.languagesList.expDate > Date.now()) {
      return caches.languagesList.response;
    }
    try {
      const { data } = await APIWithoutToken.get('languages', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });
      caches.languagesList = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: data.languages,
      };

      return data.languages;
    } catch (e) {
      return [];
    }
  }
}

export default LanguagesSvc;
