import { createRef, PureComponent } from 'react';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InView } from 'react-intersection-observer';
import * as storyFiltersActions from '../../../actions/storyFilters.action';
import * as storyActions from '../../../actions/stories.actions';
import * as watchlistActions from '../../../actions/watchlistActions';
import * as searchActions from '../../../actions/searchActions';
import permissionsDecorator from '../../../decorators/permissionsDecorator';
import withComponentName from '../../../decorators/withComponentName';

import PlayVideoComponent from '../../PlayVideoComponent';

import { capitalize } from '../../../helpers/capitalize';
import { uuid } from '../../../utils';
import { updateUserNewsFeedView } from '../../../helpers/storyFilterHelpers';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class SwitchViewButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkStatus: '',
      inView: false,
    };
    autoBind(this);

    this.rows = createRef();
    this.compact = createRef();
    this.tiles = createRef();
    this.id = uuid();
  }

  componentDidMount() {
    const { userCompactView, userNewsfeedView } = this.props;

    this.setState({
      checkStatus: userCompactView ? 'compact' : userNewsfeedView,
    });
  }

  componentDidUpdate(prevProps) {
    const { userNewsfeedView, userCompactView } = this.props;
    if (
      userNewsfeedView !== prevProps.userNewsfeedView
      || userCompactView !== prevProps.userCompactView
    ) {
      this.setState({
        checkStatus: userCompactView ? 'compact' : userNewsfeedView,
      });
    }
  }

  toggleCheck(status) {
    const {
      actions,
      piwikEnabled,
      token,
    } = this.props;

    updateUserNewsFeedView({
      status,
      actions,
      token,
    });

    if (piwikEnabled) {
      _paq.push(
        status ? (
          ['trackEvent', 'Filters', `${capitalize(status)} view ON`]
        ) : (
          ['trackEvent', 'Filters', `${capitalize(status)} view OFF`]
        ),
      );
    }

    this.setState({
      checkStatus: status,
    }, () => {
      const { onUserNewsfeedView } = this.props;
      onUserNewsfeedView(status);
    });
  }

  render() {
    const { checkStatus, inView } = this.state;
    const {
      isSearch, theme, alwaysShow, isHomePage, isNewsPage, onboarding,
    } = this.props;

    return (
      <InView
        as="div"
        className={`${cx('switch_view_buttons', theme, {
          'search-page': isSearch,
          'always-show': alwaysShow,
          home_page: isHomePage,
          news_page: isNewsPage,
          onboarding,
        })}
        })} switch_view_buttons`}
        onChange={(inView) => this.setState({ inView })}
        skip={inView}
        delay={100}
      >
        <div
          className={cx('switch_view', { active: checkStatus === 'rows' })}
        >
          <input
            type="radio"
            id={`rows-${this.id}`}
            name="switch_view"
            aria-label={`rows-${this.id}`}
            onChange={() => this.toggleCheck('rows')}
            ref={this.rows}
            checked={checkStatus === 'rows'}
          />
          <label
            htmlFor={`rows-${this.id}`}
            onClick={() => this.toggleCheck('rows')}
            className={cx('rows')}
          />
          <div className={cx('tooltip', { home: isHomePage })}>
            Rows View
          </div>
          {onboarding && (
            <span
              onClick={() => this.toggleCheck('rows')}
              className={cx('switch_view__text')}
            >
              Rows
            </span>
          )}
        </div>
        <div
          className={cx('switch_view', { active: checkStatus === 'compact' })}
        >
          <input
            type="radio"
            id={`compact-${this.id}`}
            aria-label={`compact-${this.id}`}
            name="switch_view"
            onChange={() => this.toggleCheck('compact')}
            ref={this.compact}
            checked={checkStatus === 'compact'}
          />
          <label
            htmlFor={`compact-${this.id}`}
            onClick={() => this.toggleCheck('compact')}
            className={cx('compact')}
          />
          <div className={cx('tooltip', { home: isHomePage })}>
            Compact View
          </div>
          {onboarding && (
            <span
              onClick={() => this.toggleCheck('compact')}
              className={cx('switch_view__text')}
            >
              Compact
            </span>
          )}
        </div>
        <div
          className={cx('switch_view', { active: checkStatus === 'tiles' })}
        >
          <input
            type="radio"
            id={`tiles-${this.id}`}
            name="switch_view"
            aria-label={`tiles-${this.id}`}
            onChange={() => this.toggleCheck('tiles')}
            ref={this.tiles}
            checked={checkStatus === 'tiles'}
          />
          <label
            htmlFor={`tiles-${this.id}`}
            onClick={() => this.toggleCheck('tiles')}
            className={cx('tiles')}
          />
          <div className={cx('tooltip', { home: isHomePage })}>
            Tiles View
          </div>
          {onboarding && (
            <span
              onClick={() => this.toggleCheck('tiles')}
              className={cx('switch_view__text')}
            >
              Tiles
            </span>
          )}
        </div>
        {!onboarding && inView && (
          <PlayVideoComponent
            videoLink="https://cityfalcon.wistia.com/medias/ld0y8ltub3"
            videoLinkMobile="https://cityfalcon.wistia.com/medias/ktxo5yh7gp"
            additionalStyles={cx('switch_play_icon', { switch_play_button: isHomePage })}
            tooltipAdditionalContentStyle={cx('switch_play_icon_tooltip')}
          />
        )}
      </InView>
    );
  }
}

SwitchViewButtons.defaultProps = {
  theme: 'default',
  onUserNewsfeedView: () => null,
  alwaysShow: false,
};

function mapStateToProps(state) {
  return {
    storyFilters: state.storyFilters,
    token: state.watchlistReducer.userToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...storyFiltersActions,
      ...storyActions,
      ...watchlistActions,
      ...searchActions,
    }, dispatch),
  };
}

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(permissionsDecorator(SwitchViewButtons)),
);
