import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import withComponentName from '../../../decorators/withComponentName';
import permissionsDecorator from '../../../decorators/permissionsDecorator';

import { toggleUserSimplifiedView } from '../../../actions/storyFilters.action';

import StorageSvc from '../../../services/StorageSvc';

import { uuid } from '../../../utils';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const SimplifiedLayoutButtons = ({
  status: isSimpleLayout,
  theme,
  onSimplifiedViewTabsClick,
  skipDataStoring,
  isAdvancedLayoutDisabled,
  isHomePage,
  isNewsPage,
  onboarding,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(({ watchlistReducer }) => (
    watchlistReducer.userToken
  ));
  const [checkStatus, setCheckStatus] = useState('');
  const { current: id } = useRef(uuid());

  const toggleCheck = (status) => {
    if (isAdvancedLayoutDisabled) return;
    onSimplifiedViewTabsClick(status === 'advanced');

    if (!skipDataStoring) {
      dispatch(toggleUserSimplifiedView(status === 'advanced', token));
      const isAdvancedLayout = status === 'advanced';
      StorageSvc.setItem(
        'userSimplifiedView',
        isAdvancedLayout,
      );
    }
    setCheckStatus(
      status,
    );
  };

  useEffect(() => {
    if (isSimpleLayout === undefined) return;

    setCheckStatus(isSimpleLayout ? 'advanced' : 'simple');
  }, [isSimpleLayout]);

  return (
    <div
      className={cx('simplified_layout_buttons', {
        disabled: isAdvancedLayoutDisabled,
        home_page: isHomePage,
        news_page: isNewsPage,
        [theme]: true,
        onboarding,
      })}
    >
      {
        isAdvancedLayoutDisabled && (
          <div className={cx('disabledMessage')}>
            Results sorted by search relevance. This filter is ignored.
          </div>
        )
      }
      <div
        className={cx('simplified_layout', {
          active: checkStatus === 'simple',
          disabled: isAdvancedLayoutDisabled,
          simplified_layout_home: isHomePage,
        })}
      >
        <input
          type="radio"
          id={`simple-${id}`}
          name="simplified_layout"
          aria-label={`simple-${id}`}
          onChange={() => toggleCheck('simple')}
          checked={checkStatus === 'simple'}
        />
        <label
          htmlFor={`simple-${id}`}
          onClick={() => toggleCheck('simple')}
          className={cx('simple', { simple_home: isHomePage })}
        />
        <div className={cx('tooltip')}>
          Simple Layout
          <p className={cx('tooltip_content')}>
            See&nbsp;
            <a
              href="https://help.cityfalcon.com/hc/en-us/articles/360018530834#simplelayout"
              target="_blank"
              rel="noreferrer"
            >
              Knowledge Base
            </a>
            {' '}
            for more info
          </p>
        </div>
        {onboarding && (
          <span
            className={cx('simplified_layout__text')}
            onClick={() => toggleCheck('simple')}
          >
            Simple
          </span>
        )}
      </div>
      <div
        className={cx('simplified_layout', { active: checkStatus === 'advanced', disabled: isAdvancedLayoutDisabled })}
      >
        <input
          type="radio"
          id={`advanced-${id}`}
          name="simplified_layout"
          aria-label={`advanced-${id}`}
          onChange={() => toggleCheck('advanced')}
          checked={checkStatus === 'advanced'}
        />
        <label
          htmlFor={`advanced-${id}`}
          onClick={() => toggleCheck('advanced')}
          className={cx('advanced')}
        />
        <div className={cx('tooltip')}>
          Advanced Layout
          <p className={cx('tooltip_content')}>
            See&nbsp;
            <a
              href="https://help.cityfalcon.com/hc/en-us/articles/360018530834#advancedlayout"
              target="_blank"
              rel="noreferrer"
            >
              Knowledge Base
            </a>
            {' '}
            for more info
          </p>
        </div>
        {onboarding && (
          <span
            className={cx('simplified_layout__text')}
            onClick={() => toggleCheck('advanced')}
          >
            Advanced
          </span>
        )}
      </div>
    </div>
  );
};

SimplifiedLayoutButtons.defaultProps = {
  theme: 'default',
};

export default withComponentName((permissionsDecorator(SimplifiedLayoutButtons)));
