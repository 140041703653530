import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';

import Styles from '../story-filters-styles.module.scss';

const cx = classNames.bind(Styles);

const FilterButtonInfo = ({
  rbi, dropdownTitle,
  openFilter, selectedValue = '',
  buttonErr, width, hideLabel,
}) => {
  const truncateButtonTitle = () => {
    let selectedValText;
    if (width >= 768) {
      selectedValText = selectedValue.length > 20 ? `${selectedValue.slice(0, 20)}...` : selectedValue;
    } else {
      selectedValText = selectedValue.length > 30 ? `${selectedValue.slice(0, 30)}...` : selectedValue;
    }
    return selectedValText;
  };

  return (
    <>
      <div className={cx('story-filter__btn--inner')}>
        <p
          className={cx('story-filter__btn--value', { error: buttonErr })}
        >
          {buttonErr && <FontAwesomeIcon icon={faExclamationCircle} />}
          {truncateButtonTitle()}
        </p>
        <p className={cx('story-filters__btn--title', 'mob', { rbi })}>{dropdownTitle}</p>
        {!hideLabel && (
          <p className={cx('story-filters__btn--label', 'mob', 'desktop', { rbi })}>
            {dropdownTitle}
          </p>
        )}
      </div>
      <div
        className={cx(
          'dropdown-chevron-open',
          { 'show-always': hideLabel },
        )}
      >
        <FontAwesomeIcon
          icon={openFilter ? faChevronUp : faChevronDown}
        />
      </div>
    </>
  );
};

export default FilterButtonInfo;
