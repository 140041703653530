import StorageSvc from '../services/StorageSvc';

export const updateUserNewsFeedView = ({
  status,
  actions,
  token,
}) => {
  if (status === 'compact') {
    actions.toggleUserNewsfeedViewCompact(true, token);
    StorageSvc.setItem(
      'userNewsfeedViewCompact',
      JSON.stringify({ status: true }),
    );
  } else {
    actions.toggleUserNewsfeedView(status, token);
    StorageSvc.setItem(
      'userNewsfeedView',
      JSON.stringify({ status }),
    );
    StorageSvc.setItem(
      'userNewsfeedViewCompact',
      JSON.stringify({ status: false }),
    );
  }
};

export default updateUserNewsFeedView;
