import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import Styles from './story-filters-styles.module.scss';

const cx = classNames.bind(Styles);

const FiltersMobileModal = ({
  openFilter, handleCloseModal, children, classname, showCloseIcon, allowScrolling,
}) => {
  const handleClick = (e) => {
    if (handleCloseModal) {
      handleCloseModal(e);
    }
  };

  return (
    <Modal
      show={openFilter}
      onHide={handleClick}
      className={cx('filters-mobile-modal', classname && classname, { 'disable-scrolling': !allowScrolling })}
      centered
    >
      {showCloseIcon && (
        <FontAwesomeIcon
          className={cx('dropdown_close')}
          onClick={handleClick}
          icon={faTimes}
        />
      )}
      {children}
    </Modal>
  );
};

export default FiltersMobileModal;
